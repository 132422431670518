<template>
  <div
    class="row item-details-page"
  >
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Object Title
        </v-subheader>
        <v-text-field
          v-model="objectTitle"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Main Keyword
        </v-subheader>
        <v-text-field
          v-model="objectMainKeyword"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Meta Keywords
        </v-subheader>
        <v-text-field
          v-model="objectMetaKeywords"
          single-line
          readonly
          outlined
          dense
          hide-details
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Optional Keywords
        </v-subheader>
        <v-text-field
          v-model="objectOptionalKeywords"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Specific Keywords
        </v-subheader>
        <v-text-field
          v-model="objectSpecificKeywords"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Special Characters Words
        </v-subheader>
        <v-text-field
          v-model="objectSpecialCharactersWords"
          single-line
          readonly
          outlined
          dense
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'

  export default {
    mixins: [validationMixin],
    props: {
      object: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        objectTitle: '',
        objectMainKeyword: '',
        objectMetaKeywords: '',
        objectOptionalKeywords: '',
        objectSpecificKeywords: '',
        objectSpecialCharactersWords: '',
      }
    },
    created () {
      if (this.object) {
        this.objectTitle = this.object.keyword.name || ''
        this.objectMainKeyword = this.object.objectMainKeyword || ''
        this.objectMetaKeywords = this.object.keyword.metaKeywords || ''
        this.objectOptionalKeywords = this.object.keyword.optionalKey || ''
        this.objectSpecificKeywords = this.object.objectSpecificKeywords || ''
        this.objectSpecialCharactersWords = this.object.objectSpecialCharactersWords || ''
      }
    },
  }
</script>
